
import React, { useEffect } from "react";
import Particles from "react-tsparticles";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";


ChartJS.register(ArcElement, Tooltip, Legend);

 const headerQuote = " “Saving is a great habit.. but without investing and tracking it just sleeps.” ~Manoj Arora";
  const contract = "0x77Ad5F358CD673FCA1673e20E928B3CcADC84706";

const Header = () => {


  return (
    <div className="hero-main layout-2 white-sec">
      <div id="silver-tech-bg">

      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-6 hero-left">
            <div className="sec-title">
              <h1>
                Take Control of your Finances <span>Build your Nest!</span>
              </h1>
            </div>
            <p>
              {headerQuote}
            </p>
            <div className="hero-btns">
              {/* <a href="https://discord.com/invite/7FnvFwgfhX" className="btn">
                Join Discord
              </a> */}
              <a href=" https://pancakeswap.finance/swap?outputCurrency=0x77Ad5F358CD673FCA1673e20E928B3CcADC84706" className="btn btn3">
                BUY $PBIRD
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 hero-right">
            <img src="assets/images/pepperbird-left-logo.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
