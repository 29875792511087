import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {Button, Menu, MenuItem} from "@mui/material";
import {analytics, EVENT_DAPP_CLICK, SCREEN_MAINPAGE, WEBPAGE} from "./GoogleAnalyticsFunctions";
import {logEvent} from "firebase/analytics";


const Header = () => {

  const [offset, setOffset] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onScroll = () => {
    if (window.pageYOffset > 50) {
      setOffset(true);
    } else {
      setOffset(false);
    }
  };

  useEffect(() => {
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const outGoingLink = (link: string, event: string) => {
    logEvent(analytics, event, {
      firebase_screen: SCREEN_MAINPAGE,
      firebase_screen_class: WEBPAGE
    })
    window.open(link, "_self");
  }

  return (
    <header className={offset ? "fixed" : ""}>
      <div className="container">
        <div className="row">
          <div className="col-sm-4 logo">
            <a href="index.html" title="Pepperbird Finance">
              <img
                className="light"
                src="https://storage.googleapis.com/pepperbird-www/images/logo/logo-dark.png"
                alt="Pepperbird Finance"
              />
              <img
                className="dark"
                src="https://storage.googleapis.com/pepperbird-www/images/logo/logo-light2.png"
                alt="Pepperbird Finance"
              />
            </a>
          </div>
          <div className="col-sm-8 main-menu">
            <div className="menu-icon">
              <div>
              <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
              >
                <span className="top"></span>
                <span className="middle"></span>
                <span className="bottom"></span>
              </Button>
              <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
              >
                <MenuItem onClick={handleClose}><Link to="/">Home</Link></MenuItem>
                <MenuItem onClick={handleClose}><a href="#token">Token</a></MenuItem>
                <MenuItem onClick={handleClose}><a href="#team">Team</a></MenuItem>
                <MenuItem onClick={handleClose}><a href="https://github.com/coinscope-co/audits/blob/main/pbird/audit.pdf"  target="_blank">Audit</a></MenuItem>
                <MenuItem onClick={handleClose}><a href="https://whitepaper.pepperbird.finance/">Whitepaper</a></MenuItem>
                {/*  <MenuItem onClick={handleClose}><a href="https://dapp.pepperbird.finance/">Dashboard</a></MenuItem> */}
              </Menu>
            </div>
    </div>
            <nav className="onepage">
              <ul>
                <li className="active">
                  <Link to="/">Home</Link>
                </li>
                <li><a href="#token">token</a></li>
                <li><a href="#team">team</a></li>
                <li><a href="https://github.com/coinscope-co/audits/blob/main/pbird/audit.pdf"  target="_blank">Audit</a></li>
                <li><a href="https://whitepaper.pepperbird.finance/">Whitepaper</a></li>
                {/* <!--   <li><a href="#faq">faq</a></li> --> */}
                {/*   <li className="nav-btn">
                  <a onClick={()=> outGoingLink('https://dapp.pepperbird.finance/', EVENT_DAPP_CLICK)}>Dashboard</a>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
