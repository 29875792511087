import {logEvent} from "firebase/analytics";
import {analytics, SCREEN_MAINPAGE, WEBPAGE} from "../components/GoogleAnalyticsFunctions";

export const outGoingLink = (link: string, event: string, targetSelf: boolean) => {
    let target = '_self'
    if (!targetSelf) {
        target = '_blank'
    }
    logEvent(analytics, event, {
        firebase_screen: SCREEN_MAINPAGE,
        firebase_screen_class: WEBPAGE
    })

    window.open(link, target);
}