import React, {Fragment, useEffect, useState} from "react";
import Particles from "react-tsparticles";
import {Chart as ChartJS, ArcElement, Tooltip, Legend,} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import VideoModal from "../components/VideoModal";
import Header from "../components/Header";
import {analytics, BSC_SCAN_URL} from "../index";
import {getAnalytics, logEvent} from "firebase/analytics";
import {Button} from "@mui/material";
import {onClickUrl} from "../functions/handleNewTab";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.plugins.legend.display = false;

const Home = () => {
    const screenName = 'Pepperbird_Finance_Home';
    const screenClass = 'Webpage';
    const [isVideoShow, setIsVideoShow] = useState(false);

    useEffect(() => {
    }, []);

    const chartOneData = {
        labels: ["Initial Backers", "PreSale & Liquidity", "Operations", "Burn", "Founders & Team"],
        datasets: [
            {
                label: "Initial Token Distribution",
                data: [5, 37, 15, 25, 18],
                backgroundColor: [
                    "#7d7d7d",
                    "#fbbd18",
                    "#f6aa35",
                    "#5f9747",
                    "#d47c0c",

                ],
                borderColor: [
                    "#7d7d7d",
                    "#fbbd18",
                    "#f6aa35",
                    "#5f9747",
                    "#d47c0c",

                ],
                borderWidth: 1,
            },
        ],
    };

    const chartTwoData = {
        labels: ["Reflections", "Liquidity", "Charity", "Buy Back", "Operations"],
        datasets: [
            {
                label: "Tokenomics",
                data: [6, 3, 1, 1, 1],
                backgroundColor: [
                    "#fbbd18",
                    "#d47c0c",
                    "#7d7d7d",
                    "#f6aa35",
                    "#5f9747",
                ],
                borderColor: ["#fbbd18", "#d47c0c", "#7d7d7d", "#f6aa35", "#5f9747"],
                borderWidth: 1,
            },
        ],
    };

    logEvent(analytics, 'screen_view', {
        firebase_screen: screenName,
        firebase_screen_class: screenClass
    });

    const textAboutMainTitle = "Budget, Track, Invest"
    const textAboutMainText = "Understand and grow your net worth with our comprehensive finance tracking system JenFin";
    const textAboutMainTextTwo = "Pepperbird Finance is a decentralized ecosystem built to put you in the driver seat of your " +
        "financial future. Budget, track and manage all of your investments in one place"
    const textAboutMainTextThree = "The Pepperbird platform powered by JenFin, a power house of analytics, is designed to aid in the budgeting, tracking and management of both your TradFi and DeFi Assets. We believe in investment strategies that takes into account your entire financial situation. Make better investment decisions, Create detail budgets, Track your Staking Pools, Harvest taxable losses and gauge sentiments on DeFi projects that matter to you. $PBIRD is more than a token rewarding holders with dividends, it is a Bird's Eye view of your path for financial freedom."
    const textAboutMainTextTagline = "Make Smart Money Moves! Buy-Hold and Enjoy the Flight";

    const raishadProfile = () => {
        return                      <div className="col wow fadeInUp">
            <div className="team">
                <img src="assets/images/raishad_g.png" alt="team"/>
                <h4>Raishad</h4>
                <span>NFT Innovation Lead</span>
            </div>
            <div className="team-social">
                <ul>
                    <li><a href="https://twitter.com/jeneneal" target="_blank"><i
                        className="fab fa-twitter"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/carmenjglover/" target="_blank"><i
                        className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="https://www.instagram.com/jenelove7" target="_blank"><i
                        className="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    }



    const tokenStructureSection = () => {
        return <div className="token-sale p-tb" id="token">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="empty-45"></div>
                        <div className="sec-title text-center">
                            <h3>Token Structure</h3>
                            <div className="sub-txt mw-650 text-center">
                                <p>100 Trillion Initial Supply | 12% Transaction Tax</p>
                            </div>
                        </div>
                        <div className="sub-txt mw-650 text-center">
                            <Button
                                onClick={onClickUrl(BSC_SCAN_URL + '/address/0x77Ad5F358CD673FCA1673e20E928B3CcADC84706')}
                                variant="text"><OpenInNewIcon sx={{mr: 1}}/>
                                Contract: 0x77Ad5F358CD673FCA1673e20E928B3CcADC84706</Button>
                        </div>
                    </div>
                    <div className="col-md-6 wow zoomIn">
                        <div className="sale-chart-view">
                            <div className="doughnut">
                                <div className="doughnutChartContainer">
                                    <Doughnut data={chartOneData}/>
                                </div>
                                <div id="legend" className="chart-legend">
                                    <ul className="doughnut-legend">
                                        <li>
                            <span
                                className="doughnut-legend-icon"
                                style={{backgroundColor: "#7d7d7d"}}
                            ></span>
                                            <span className="doughnut-legend-text">
                              5% Initial Backers
                            </span>
                                        </li>
                                        <li>
                            <span
                                className="doughnut-legend-icon"
                                style={{backgroundColor: "#fbbd18"}}
                            ></span>
                                            <span className="doughnut-legend-text">
                              37% Pre-Sale &amp; Liquidity
                            </span>
                                        </li>
                                        <li>
                            <span
                                className="doughnut-legend-icon"
                                style={{backgroundColor: "#f6aa35"}}
                            ></span>
                                            <span className="doughnut-legend-text">
                              15% Operations
                            </span>
                                        </li>
                                        <li>
                            <span
                                className="doughnut-legend-icon"
                                style={{backgroundColor: "#5f9747"}}
                            ></span>
                                            <span className="doughnut-legend-text">
                              25% Burned
                            </span>
                                        </li>
                                        <li>
                            <span
                                className="doughnut-legend-icon"
                                style={{backgroundColor: "#d47c0c"}}
                            ></span>
                                            <span className="doughnut-legend-text">
                              18% Founders &amp; Team
                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 wow zoomIn">
                        <div className="sale-chart-view">
                            <div className="doughnut">
                                <div className="doughnutChartContainer">
                                    <Doughnut data={chartTwoData}/>
                                </div>
                                <div id="legend" className="chart-legend">
                                    <ul className="doughnut-legend">
                                        <li>
                            <span
                                className="doughnut-legend-icon"
                                style={{backgroundColor: "#fbbd18"}}
                            ></span>
                                            <span className="doughnut-legend-text">
                              6% Reflections
                            </span>
                                        </li>
                                        <li>
                            <span
                                className="doughnut-legend-icon"
                                style={{backgroundColor: "#d47c0c"}}
                            ></span>
                                            <span className="doughnut-legend-text">
                              3% Liquidity
                            </span>
                                        </li>

                                        <li>
                            <span
                                className="doughnut-legend-icon"
                                style={{backgroundColor: "#7d7d7d"}}
                            ></span>
                                            <span className="doughnut-legend-text">
                              1% Charity
                            </span>
                                        </li>
                                        <li>
                            <span
                                className="doughnut-legend-icon"
                                style={{backgroundColor: "#f6aa35"}}
                            ></span>
                                            <span className="doughnut-legend-text">
                              1% Buy Back
                            </span>
                                        </li>
                                        <li>
                            <span
                                className="doughnut-legend-icon"
                                style={{backgroundColor: "#5f9747"}}
                            ></span>
                                            <span className="doughnut-legend-text">
                              1% Operations
                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const leaderShipTeamSection = () => {
        return <div className="team-section p-tb" id="team">
            <div className="container">
                <div className="team-member text-center">
                    <div className="sec-title text-center">
                        <h3>Our Leadership Team</h3>
                    </div>
                    <div className="sub-txt text-center">
                        <div className="row">
                            <div className="col wow fadeInUp">
                                <div className="team">
                                    <img src="assets/images/carmen_n_002.png" alt="team"/>
                                    <h4>Carmen</h4>
                                    <span>Founder & CEO</span>
                                </div>
                                <div className="team-social">
                                    <ul>
                                        <li><a href="https://twitter.com/jeneneal" target="_blank"><i
                                            className="fab fa-twitter"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/carmenjglover/" target="_blank"><i
                                            className="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="https://www.instagram.com/jenelove7" target="_blank"><i
                                            className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col wow fadeInUp" data-wow-delay="0.1s">
                                <div className="team">
                                    <img src="assets/images/kareem_g.png" alt="team"/>
                                    <h4>Kareem</h4>
                                    <span>CTO & Lead Developer</span>
                                </div>
                                <div className="team-social">
                                    <ul>
                                        <li><a href="https://twitter.com/thisiskareemg" target="_blank"><i
                                            className="fab fa-twitter"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/kareemglover/" target="_blank"><i
                                            className="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="https://www.instagram.com/thisiskareemg" target="_blank"><i
                                            className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const aboutUsSection = () => {
        return <div className="about-section style-2 p-tb" id="about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-5 about-left">
                        <div className="sec-title">
                            <h1>{textAboutMainTitle}</h1>
                            <h5>
                                {textAboutMainText}
                            </h5>
                        </div>
                    </div>
                    <div className="col-md-7 about-left">
                        <a
                            className="wow rotateIn fancybox-media"
                            onClick={() => setIsVideoShow(true)}
                        >
                            <img
                                src="assets/images/play-btn-2.png"
                                alt=""
                                className="play-btn-img"
                            />
                        </a>
                        <VideoModal
                            show={isVideoShow}
                            onHide={() => setIsVideoShow(false)}
                        />
                        <h5 className="accent-color-two">
                            {textAboutMainTextTwo}
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="empty-30"></div>
                        <p className="lead">

                            {textAboutMainTextThree}
                                </p>
                        <p className="lead">{textAboutMainTextTagline}</p>
                        <a className="btn" href="https://whitepaper.pepperbird.finance">
                            White paper
                        </a>
                    </div>
                </div>
            </div>
        </div>
    }

    const benefitSection = () => {
        return <div className="benefitsKG p-tb c-l">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading">
                        Reasons to Love <span>Our Ecosystem</span>
                    </h2>
                </div>
                <div className="benefits-box row">
                    <div className="col-md-3">
                        <div className="bf-icon">
                            <img src="assets/images/Time_1.png" alt=""/>
                        </div>
                        <h3>Multiple Reflections</h3>
                        <p>6% Reflections customized to fit your investment goals. Chose three different reflections (2% each) or just one. </p>
                    </div>
                    <div className="col-md-3">
                        <div className="bf-icon">
                            <img src="assets/images/Cloud-Based.png" alt=""/>
                        </div>
                        <h3>NFT Boosters</h3>
                        <p>
                            Buy and hold one of our NFT while staking and earn a higher
                            APY
                        </p>
                    </div>
                    <div className="col-md-3">
                        <div className="bf-icon">
                            <img src="assets/images/No-transaction-fees.png" alt=""/>
                        </div>
                        <h3>Extra Income</h3>
                        <p>Percentage of all revenue generated by the ecosystem goes to the reflection pool.</p>
                    </div>
                    <div className="col-md-3">
                        <div className="bf-icon">
                            <img src="assets/images/Instant-operations.png" alt=""/>
                        </div>
                        <h3>Charity Focus</h3>
                        <p>
                            Give a little, help a lot. 1% of all transaction tax goes to
                            charity.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    }

    const roadMapSection = () => {
        return <div className="roadmap-sec p-tb white-sec" id="roadmap">
            <div className="container">
                <div className="sec-title text-center">
                    <h3>The Roadmap</h3>
                </div>
                <div className="sub-txt text-center">
                    <p>Summary of 2022 - 2025 Roadmap</p>
                </div>
                <div className="vertical-roadmap">
                    <div className="roadmap-item done">
                        <div className="title">
                            <span>Phase One</span>
                            <h4>Research and Development </h4>
                        </div>
                        <div className="roadmap-text">
                            <p>
                                <del>Define the purpose of Pepperbird Finance and Token</del>
                            </p>
                            <p>
                                <del>Research, Identify, develop and test token structure</del>
                            </p>
                            <p>
                                <del>Comparison analysis of pre-selected tokens to maximize
                                    reflections
                                </del>
                            </p>
                            <p>
                                <del>Create social media accounts, website and Lite Paper</del>
                            </p>
                        </div>
                    </div>
                    <div className="roadmap-item done">
                        <div className="title">
                            <span>Preparing for Launch</span>
                            <h4>Marketing</h4>
                        </div>
                        <div className="roadmap-text">
                            <p>
                                <del>Website launch</del>
                            </p>
                            <p>
                                <del>Post launch and presale marketing campaign</del></p>
                            <p>
                                <del>Complete Reflection Calculator Dashboard</del></p>
                        </div>
                    </div>
                    <div className="roadmap-item done">
                        <div className="title">
                            <span>Phase Two</span>
                            <h4>Launch Presale and PancakeSwap</h4>
                        </div>
                        <div className="roadmap-text">
                            <p>
                                <del>Private Sale</del>
                            </p>
                            <p>Marketing Campaign v2</p>
                            <p>
                                <del>Complete audit and KYC</del>
                            </p>
                            <p><del>Presale</del></p>
                            <p><del>PancakeSwap</del></p>
                            <p><del>Onboarding New Hires</del></p>
                        </div>
                    </div>
                    <div className="roadmap-item active" >
                        <div className="title">
                            <span>Phase Three</span>
                            <h4>Staking and Farming dApp </h4>
                        </div>
                        <div className="roadmap-text">
                            <p>
                                <del>Custom reflection dashboard</del>
                            </p>
                            <p>
                                <del>Gasless dashboard</del>
                            </p>
                            <p><del>Implementing staking</del></p>
                            <p>Implementing farming</p>
                            <p>NFT Boosters</p>
                            <p><del>Launch Discord & Instagram</del></p>
                            <p>
                                JenFin Fiat and Crypto Wallet (Percentage of membership fees goes
                                to reflection pool)
                            </p>
                        </div>
                    </div>
                    <div className="roadmap-item">
                        <div className="title">
                            <span>1st Charity Donation</span>
                            <h4>Identify Partner Charities</h4>
                        </div>
                        <div className="roadmap-text">
                            <p>
                                1% of every transaction goes to charity. 0.5% to a charity
                                focus on outreach to Liberia, West Africa and other
                                African Nations. 0.5% to charities focus on veterans,
                                women and children
                            </p>
                            <p>Influencer Marketing</p>
                        </div>
                    </div>
                    <div className="roadmap-item">
                        <div className="title">
                            <span>Phase Four</span>
                            <h4>Development of the platform</h4>
                        </div>
                        <div className="roadmap-text">
                            <p>Team Expansion</p>
                            <p>
                                Merch Store (Percentage of all sales goes to the reflection pool)
                            </p>
                            <p>API Marketplace (Percentage of all sales goes to the reflection pool)</p>
                            <p>2nd Charity Donation</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }



    return (
        <div className="wrapper" id="top">
            <Navbar/>
            <div className="midd-container">
                <Header/>
                {/* About Us Section Start */}
                {aboutUsSection()}
                {/* About Us Section End */}

                {/* Benefit Section Start */}
                {benefitSection()}
                {/* Benefit Section Ends */}

                {/* Token Structure Section Start */}
                {tokenStructureSection()}
                {/* Token Structure Section Ends */}

                {/* RoadMap Section Start */}
                {roadMapSection()}
                {/* RoadMap Section End */}

                {/* Leadership Team Section Start */}
                {leaderShipTeamSection()}
                {/* Leadership Team Section End */}


                <div className="clear"></div>
                <Footer/>
            </div>
        </div>
    );
};

export default Home;
