import { getAnalytics, logEvent } from "firebase/analytics";
import {app} from "../configs/firebaseConfig";

export const WEBPAGE = 'Webpage'
export const analytics = getAnalytics(app);

export const SCREEN_MAINPAGE = "Pepperbird_Finance"
export const EVENT_DAPP_CLICK = 'NAV_DAPP_Clicked'

export function gaLogPageVisit(pageName: string) {

    logEvent(analytics, 'screen_view', {
        firebase_screen: pageName,
        firebase_screen_class: WEBPAGE
    });

}